export const environment = {
  production: false,
  clientId: '4705640736.int_testing.f51e2259-29a9-4ebc-b4e7-5c2147cf6bb3.apps',
  authority:
    'https://am.nhsint.auth-ptl.cis2.spineservices.nhs.uk:443/openam/oauth2/realms/root/realms/NHSIdentity/realms/Healthcare',
  responseType: 'code',
  scopes: [
    'openid',
    'profile',
    'email',
    'nhsperson',
    'associatedorgs',
    'nationalrbacaccess',
    'professionalmemberships',
    'organisationalmemberships',
  ],
  redirectUrl: 'https://chis-hcp-dev.scwcsu.nhs.uk/login',
};
